import { type FC } from 'react'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokPageHeadingType } from '../../model/storyblokTypes.generated'

export const StoryblokPageHeading: FC<
  StoryblokComponentProps<StoryblokPageHeadingType>
> = ({ blok }) => {
  if (!blok.heading) {
    return null
  }

  return <h1 className="text-2xl font-medium">{blok.heading}</h1>
}
